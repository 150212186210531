// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-aboutus-page-js": () => import("./../../../src/templates/aboutus-page.js" /* webpackChunkName: "component---src-templates-aboutus-page-js" */),
  "component---src-templates-bios-page-js": () => import("./../../../src/templates/bios-page.js" /* webpackChunkName: "component---src-templates-bios-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-default-page-js": () => import("./../../../src/templates/default-page.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-download-page-js": () => import("./../../../src/templates/download-page.js" /* webpackChunkName: "component---src-templates-download-page-js" */),
  "component---src-templates-feature-page-js": () => import("./../../../src/templates/feature-page.js" /* webpackChunkName: "component---src-templates-feature-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-login-page-js": () => import("./../../../src/templates/login-page.js" /* webpackChunkName: "component---src-templates-login-page-js" */),
  "component---src-templates-news-list-js": () => import("./../../../src/templates/news-list.js" /* webpackChunkName: "component---src-templates-news-list-js" */),
  "component---src-templates-pharmacies-page-js": () => import("./../../../src/templates/pharmacies-page.js" /* webpackChunkName: "component---src-templates-pharmacies-page-js" */),
  "component---src-templates-providers-page-js": () => import("./../../../src/templates/providers-page.js" /* webpackChunkName: "component---src-templates-providers-page-js" */),
  "component---src-templates-rx-capture-page-js": () => import("./../../../src/templates/rx-capture-page.js" /* webpackChunkName: "component---src-templates-rx-capture-page-js" */),
  "component---src-templates-solutions-page-js": () => import("./../../../src/templates/solutions-page.js" /* webpackChunkName: "component---src-templates-solutions-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-the-beat-list-js": () => import("./../../../src/templates/the-beat-list.js" /* webpackChunkName: "component---src-templates-the-beat-list-js" */),
  "component---src-templates-type-playground-js": () => import("./../../../src/templates/type-playground.js" /* webpackChunkName: "component---src-templates-type-playground-js" */)
}

